import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import queryString from 'query-string';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import { getApiRequest, apiUrl, postApiRequest } from '../../../../../utils/request';
import { isBrowser, formatPhoneFromServer, formatDateTime, formatDay } from '../../../../../utils/helpers';
import Loadable from '../../../../data/loadable';
import AdminTable from '../common/admin-table';
import AutoForm from '../../../../form/auto-form';
import SEO from '../../../../layout/seo';

function formatRow(item) {
  const { id, name, email, participant_name: participantName, participant_email: participantEmail, product_name: productName, center_name: centerName, league_name: leagueName, created_at: created } = item;
  return {
    // product: <span>{productName}<br />Starts: {formatDay(startDate, 'M/D/Y')}<br />League: {leagueName}</span>,
    product: <span>{productName}<br />League: {leagueName}</span>,
    participant: <span><span className="font-bold">{participantName}</span><br />{participantEmail}</span>,
    referrer: <span><span className="font-bold">{name}</span><br />{email}</span>,
    centerName,
    created: formatDateTime(created),
    id,
  };
}

const AdminReferrals = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [queryParamsString, setQueryParamsString] = useState(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/referrals',
        label: 'Referrals',
      },
    ]);   
  }, []);

  const query = queryString.parse(isBrowser ? window.location.search : '');
  const currentQueryParamsString = queryString.stringify(query || {});

  const loadRegistrations = async () => {
    if (!loading) {
      setLoading(true);
    }
    const result = await getApiRequest(`/admin/referrals/registrations${queryParamsString ? `?${queryParamsString}` : ''}`);
    // setRegistrations(result.data);
    console.log(result, 'this is result');
    setResults(result);
    setLoading(false);
  };

  useEffect(() => {
    if (currentQueryParamsString !== queryParamsString) {
      setQueryParamsString(currentQueryParamsString);
    }
  }, [currentQueryParamsString]);


  useEffect(() => {
    if (queryParamsString !== null && queryParamsString === currentQueryParamsString) {
      loadRegistrations();
    }
  }, [queryParamsString]);

  const columns = [
    {
      key: 'participant',
      label: 'Participant',
    },
    {
      key: 'product',
      label: 'Product',
      // isBold: true,
    },
    {
      key: 'centerName',
      label: 'Center',
    },
    {
      key: 'referrer',
      label: 'Referred By',
    },
    {
      key: 'created',
      label: 'Date',
    },
    // {
    //   key: 'actions',
    //   label: '',
    //   isRight: true,
    //   render: (row) => (
    //     <Link
    //       to={`/app/admin/registration/view?id=${row.id}`}
    //       className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    //     >
    //       View
    //     </Link>
    //   ),
    // },
  ];


  const baseUrl = '/app/admin/referrals';

  const searchForm = {
    type: 'get',
    noContainer: true,
    successRedirect: '/app/admin/referrals',
    fields: [
      // {
      //   name: 'name',
      //   label: 'Name',
      //   type: 'text',
      // },
      // {
      //   name: 'email',
      //   label: 'Email',
      //   type: 'email',
      // },
      {
        name: 'center_id',
        label: 'Center',
        type: 'select-lookup',
        lookupUrl: '/options/centers/search',
        placeholder: 'Search centers by name',
      },
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: '/options/leagues',
      },
      {
        name: 'product_id',
        label: 'Product',
        type: 'select-lookup',
        lookupUrl: '/options/products/search',
        placeholder: 'Search products by name',
      },
    ],
    submitLabel: 'Search Referrals',
  };



  return (
    <AdminContainer>
      <SEO title="Referrals" />
      <Heading title="Referrals" />
      <Loadable loading={loading}>
        <AdminTable results={results} columns={columns} baseUrl={baseUrl} rowFormatter={formatRow} searchTitle="Search Referrals" searchForm={<AutoForm {...searchForm} />} />
      </Loadable>
    </AdminContainer>
  );
}

export default AdminReferrals;
